<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import { formatDate} from "@/utils/utils.js"

const routerx = useRouter();
const { article } = defineProps(["article"]);
const navto = () => {
  routerx.push(`/article/${article.id}`);
};
</script>   

<template>
  <div class="item">
    <div class="item">
      <div class="post-slide">
        <div class="post-img">
          <img
          :src="article.imgUrl"  alt=""
          />
        </div>
        <div class="post-content">
          <h3 class="post-title txt-center">
            <router-link class="" :to="`/article/${article.id}`">
              {{ article.title }}
            </router-link>
          </h3>
          <p class="post-description quill-editor q-label" dir="" v-html="article.content" />
          <div class="all-btn">
            <router-link :to="`/article/${article.id}`" class="big-btn mid-btn mb-3">
              اقرأ المزيد
            </router-link>
          </div>
          <div class="text-right">
            <i
              class="fa-regular fa-circle-user txt-center"
              style="color: #d06106; margin: 0 3px"
            ></i>
            <span class="post-date"> {{article.author.displayName}} </span>
            <span> </span>
          </div>

          <div class="text-right">
            <i
              class="fa-regular fa-clock"
              style="color: #d06106; margin: 0 3px"
            ></i>
            <span class="post-date">{{ formatDate(article.addedAt) }}</span>
            <span> </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.post-slide {
  background: var(--section-background);
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #8d8c8cb5;
}

.post-slide .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  height: 200px; /* Set a fixed height for the image container */
  min-height: 200px;
  max-height: 200px;
}

.post-slide .post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will ensure the image covers the entire container */
  object-position: center; /* This will center the image within the container */
  transition: transform 0.2s linear;
}

.post-slide:hover .post-img img {
  transform: scale(1.1, 1.1);
}

.post-slide .post-content {
  background: var(--section-background);
  padding: 1px 20px 15px;
  border-radius: 15px;
}

.post-slide .post-title a {
  font-size: 16px;
  font-weight: bold;
  color: var(--scond-color);
  /* display: inline-block; */
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  text-align: center;
  margin: 0;
}

.post-slide .post-title a:hover {
  text-decoration: none;
  color: var(--scond-color);
}

.post-slide .post-description {
  line-height: 24px;
  margin: 0;
  color: var(--main-color);
  margin-bottom: 15px;
}
.mid-btn{
  padding: 2px 10px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}
</style>
