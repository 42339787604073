<template>
  <div class="home">
   <landing/>
   <names/>
   <h2 class="txt-center mt-5 " style="">
      <span class="underline">الموقع الرسمي</span>
    </h2>
    <!-- مساحة اعلانية -->
   <AdsView/>
   <socialMedia/>



   <HomeArticleSlider 
   v-if="tribesPosts.length > 0"
   :posts="tribesPosts"
   class="container"/>

   <div
   v-if="architecturePosts.length > 0" class="container-fluid even-slider-container" >
   <HomeArticleSlider 
   :posts="architecturePosts"
   class="container"/>
  </div>
  
  <HomeArticleSlider 
  v-if="villagesPosts.length > 0"
   :posts="villagesPosts"
   class="container"/>

   <div v-if="peoplePosts.length > 0" class="container-fluid odd-slider-container" >
   <HomeArticleSlider 
      v-if="peoplePosts"
      :posts="peoplePosts"
      class="container"/>
   </div>

   <HomeArticleSlider 
   v-if="historyPosts.length > 0"
   :posts="historyPosts"
   class="container"/>
  
  </div>
</template>

<script setup>
import landing from '@/components/landing.vue'
import HomeArticleSlider from '@/components/HomeArticleSlider.vue'
import names from '@/components/names.vue'
import socialMedia from '@/components/socialMedia.vue'
import AdsView from '@/components/AdsView.vue'
import {ApiClient, CategoryControllerApi,ArticleControllerApi} from '@/api/index'
import { ref, onMounted} from 'vue'

const apiClient=new ApiClient();
const categoryApi=new CategoryControllerApi(apiClient);
const articleApi=new ArticleControllerApi(apiClient);


const tribesPosts = ref([]);
const architecturePosts = ref([]);
const villagesPosts = ref([]);
const peoplePosts = ref([]);
const historyPosts = ref([]);

function fetchHomeShowcase(){
  articleApi.getTop5ArticlesByCategory("1",1,false,(error,data,response)=>{
    if(!error){
      tribesPosts.value=data;
    }
  });  

  articleApi.getTop5ArticlesByCategory("2",1,false,(error,data,response)=>{
    if(!error)
      villagesPosts.value=data;
    }); 

  articleApi.getTop5ArticlesByCategory("3",1,false,(error,data,response)=>{
    if(!error)
      peoplePosts.value=data;
    });  
  
  articleApi.getTop5ArticlesByCategory("4",1,false,(error,data,response)=>{
    if(!error)
      historyPosts.value=data;
    });
}
onMounted(() => {
  fetchHomeShowcase();
});
</script>
<style scoped>
h2{
  color: var(--scond-color);
   font-weight: 700;
}

.even-slider-container{
  background-color: var(--section-background);
  box-shadow: 0 3px 10px rgba(117, 117, 117, 0.599);
}

@media (max-width: 767px) { 
  h2 {
    font-weight: 700;
    font-size: 26px;
  }
}
</style>
